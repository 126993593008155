
import Vue from 'vue'
import SliderTabs from '@/components/SliderTabs.vue'
import { EcosystemId, IEcosystem } from '@/ecosystem'
import { delay, equalsInsensitive } from '@/utils'
import { PresaleData } from '@/types'


const presales : {[unit: string] : object} = {
  'BSC': [
    ],
  'Moonriver': [
      // {
      //   logo: 'https://www.freeiconspng.com/thumbs/mark-zuckerberg-png/mark-zuckerberg-png-images-photos-7.png',
      //    name: 'Zucked Inu',
      //    ticker: 'ZUCK',
      //    presaleLink: '/moonriver/presale/0x86b6cC87A427EB416B4d0Ba4Fe95d0B6Ba7b4a87'
      // },
    ],
  'Moonbeam': [
    ]
}

export default Vue.extend({
  name: 'LaunchPAD',
  components: { SliderTabs },
  methods: {
    getTableContent() {
      var content = this.getImportedPresales().concat(this.getWhitelistedPresales())

      return content
    },
    getImportedPresales() {
      var presaleList : any = []
      const whiteList = presales[this.currentChain]

      const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]
      
      importedPresales.forEach((item : any) => {
        var presaleListItem = {
          logo: item.logo,
          name: item.name,
          ticker: item.ticker,
          presaleLink: item.presaleLink
        }
        if (presaleListItem.name && presaleListItem.ticker && presaleListItem.presaleLink ) {
          presaleList.push(presaleListItem)
        }
      })
      return presaleList
    },
    getWhitelistedPresales() {
      var presaleList : any = []
      var whiteList : any = presales[this.currentChain]
      var importedPresales : any = this.$store.state.userProfile.importedPresales[this.ecosystemId]

      whiteList.forEach((item: any) => {
        var presaleListItem = {
          logo: item.logo,
          name: item.name,
          ticker: item.ticker,
          presaleLink: item.presaleLink
        }
        const existingEntry = importedPresales.find((f: any) => equalsInsensitive(f.presaleLink, item.presaleLink)) 
        if (!existingEntry) {
          presaleList.push(presaleListItem)
        }
      })
      return presaleList
      },
      presaleImported(presaleLink : string) {
        if (!presaleLink) {
          return
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleLink))
        if (existingEntry) {
          return true
        }
        return false
      },
      importPresale(importedLogo : string, importedName : string, importedTicker : string, importedLink : string) {
        if (!importedName || !importedTicker || !importedLink) {
          return
        }

        const presaleConfig = {
          logo: importedLogo,
          name: importedName,
          ticker: importedTicker,
          presaleLink: importedLink
        }

        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleConfig.presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }

        this.$store.state.userProfile.importedPresales[this.ecosystemId].push(presaleConfig)
      },
      removePresale(presaleLink : string) {
        const importedPresales = this.$store.state.userProfile.importedPresales[this.ecosystemId]

        const existingEntry = importedPresales.find((f: PresaleData) => equalsInsensitive(f.presaleLink, presaleLink))
        if (existingEntry) {
          const id = importedPresales.indexOf(existingEntry)
          if (id > -1) {
            importedPresales.splice(id, 1);
           }
        }
      },
    setEcosystem(chain_id : string) {
     this.currentChain = chain_id
    },
    goToPresale(presaleAddress: string) {
      const chain = this.$store.getters.ecosystem.routeName
      this.$router.push(`/${chain}/presale/${presaleAddress}`)
    }
  },
  data() {
    return {
      currentChain: <string> '',
      active: true,
      searchText: '',
      customPresaleAddress: <string> '',
      validPresaleAddress: <boolean> false,
      headers: [
      {
        text: 'Favorite',
        value: 'favorite',
        align: 'center',
        sortable: false
      },
      {
        text: 'Logo',
        value: 'logo',
        align: 'center',
        sortable: false
      },
      {
        text: 'Ticker',
        value: 'ticker',
        align: 'center',
        sortable: false
      },
      {
        text: 'Token name',
        value: 'name',
        align: 'center',
        sortable: false
      },
      {
        text: 'Presale link',
        value: 'presaleLink',
        align: 'center',
        sortable: false
      }
    ],
    presaleAddressRules: [
        (v: any) => !!v || 'Specify the presale address',
        (v: any) => (v.length == 42 && v.slice(0, 2) == '0x') || 'Not a valid contract address.'
      ]
    }
  },
  mounted () {
    this.ecosystemId = this.$store.state.ecosystemId
  },
  created () {
    window.onstorage = () => {
      this.$store.commit('setUserProfile')
    };
  },
  beforeDestroy() {
    this.active = false
  },
  beforeRouteLeave(to: any, from: any, next: Function) {
    this.active = false
    next()
  },
  computed: {
    ecosystemId: {
      get(): EcosystemId {
        return this.$store.state.ecosystemId
      },
      set(val: EcosystemId) {
        this.$store.commit('setEcosystemId', val)
        if (val == 0) {
          this.setEcosystem("BSC")
        }
        else if (val == 1) {
          this.setEcosystem("Moonriver")
        }
        else if (val == 2) {
          this.setEcosystem("Moonbeam")
        }
      }
    },
    ecosystem(): IEcosystem {
      return this.$store.getters.ecosystem
    }
  },
  watch: {
    ecosystemId() {
      this.$padswapTheme.theme = this.$store.getters.ecosystem.theme
    },
  }
})

